.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pb-video-container {
  padding-top: 20px;
  background: #bdc3c7;
  font-family: Lato;
}

.pb-video {
  border: 2px solid #e6e6e6;
  padding: 10px;
}

.pb-video:hover {
background: #AE498C;
}

.pb-video-frame {
  transition: width 2s, height 2s;
}

/*.pb-video-frame:hover {
    height: 300px;
}*/

.pb-row {
  margin-bottom: 10px;
}

.modal-wrapper {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: #ddd;
}

.modal-container {
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  background: #fff;
  min-width: 30rem;
  padding: 10px;
}